// general app-wide strings
const app = {
  companyName: "Ogury",
  productName: "Motionly",
  welcome: "Welcome!",
  notFound: {
    title: "Page not found!",
    subtitle: "Please, try another URL.",
  },
  unauthorized: "Unauthorized access!",
  passwordConstraints: "Password must be at least 4 characters long",
  oops: "Oops! Something went wrong…",
  loadingError: "The application could not be loaded.",
  yes: "Yes",
  no: "No",
  or: "Or",
  notApplicable: "N/A",
  user: "Member",
  pager: {
    page: "Page",
    pageSize: "Page size: {{size}}",
    currentPage: "{{currentPageNumber}} of {{lastPageNumber}}",
    showing: "Showing {{min}} - {{max}} of {{total}} results",
  },
  notifications: {
    copiedToClipboard: "Copied to the clipboard.",
    applicationDataUpdatedSuccess: "Application data updated.",
    applicationDataUpdatedFailure: "An error occurred while attempting to update the application data.",
  },
  tooltip: {
    more: "More information",
  },
  openingBuilder: "Launching builder...",
};

const components = {
  fileInput: { choose: "Choose a file" },
  metricsSummaryCards: {
    technical: "Technical",
    technicalShortTooltip: "The percentage displayed accounts for the proportion of creatives actually loaded.",
    technicalLongTooltip:
      "It is equal to the ratio between the number of occurrences when an creative has generated the 'Load' event and the number of occurrences when it has generated the 'Request' event.",
    viewability: "Viewability",
    viewabilityShortTooltip: "The percentage displayed accounts for the proportion of creatives actually displayed.",
    viewabilityLongTooltip:
      "It is equal to the ratio between the number of occurrences when an creative has generated the 'Display' event and the number of occurrences when it has generated the 'Load' event.",
    open: "Open",
    openShortTooltip: "The percentage displayed accounts for the proportion of creatives actually clicked / tapped.",
    openLongTooltip:
      "It is equal to the ratio between the number of occurrences when an creative has generated the 'Open' event and the number of occurrences when it has generated the 'Display' event.",
    requestsCount: "Request: {{count}}",
    loadsCount: "Load: {{count}}",
    displaysCount: "Display: {{count}}",
    opensCount: "Open: {{count}}",
  },
  guardedModal: {
    closeQuestion: "Do you confirm that you want to close?",
    discardChangeQuestion: "Do you confirm that you want to discard your changes?",
  },
  creativesTypeSwitch: {
    experiences: "Creatives",
    sites: "Multi ad-units creatives",
  },
};

const navbar = {
  dashboard: "Dashboard",
  experiences: "Creatives",
  templates: "Templates",
  assets: "Assets",
  sites: "Sites",
  applications: "Applications",

  user: {
    profile: "Profile",
    reload: "Reload",
    settings: "Settings",
    createCustomer: "Create organization",
    documentation: "Documentation",
    support: "Support",
    logout: "Log out",
    logoutConfirmationModal: {
      title: "Log out",
      explanation: "Do you really want to log out?",
      action: "Log out",
    },
  },
  createExperienceModal: {
    title: "Creative creation",
    subtitle: "Click on a template to start the Creative Builder",
    allTemplates: "All templates",
  },
  createSiteModal: {
    title: "Add multi ad-units ",
    explanation: "A multi ad-unit contains several ad-units",

    emptyDescription: "No results found. Please try a different keyword or check your spelling.",
    success: "The multi ad-units creative has been successfully created.",
    errorTitle: "Something went wrong during the multi ad-units creative creation.",
  },
  uploadExperienceBundleModal: {
    title: "Creative Bundle Upload",
    explanation:
      "By uploading an already exported creative bundle, you will create an creative identical to the one in the bundle.",
    file: "Inputs file",
  },
  customer: {
    searchPlaceholder: "Search organizations",
    createCustomer: "Create organization",
    leaveCustomer: "Leave this organization",
    settings: "Settings",
    changeCustomerConfirmationModal: {
      title: "Organization Switch",
      description: "Do you really want to switch to this organization?",
      action: "Switch",
    },
    createCustomerModal: {
      title: "Organization Creation",
      explanation:
        "You may create a new organization, into which you may invite members and create creative which will be isolated.",
      form: {
        customerName: "Organization Name",
        customerId: "Organization ID",
        action: "Next",
      },
      confirmation: {
        description: "Please check your inputs:",
        cancel: "Go back",
      },
      errorTitle: "Could not create the organization",
    },
  },
  create: {
    fromBundle: "Import",
    experience: "Creative",
    site: "Multi ad-units creative",
    uploadSuccess: "Creative created.",
    fromDescription: "From Description",
    fromDescriptionMessage: '"From Description" is only available on the Studio application',
    requestBetaAccess: "Request beta access to the Motionly Studio",
    request: {
      subject: "Request App Beta Access",
      body:
        "Hello, I'm interested in using the desktop app of Motionly for regarding my account with e-mail '{{email}}'.",
    },
  },
  support: {
    subject: "Motionly Support Request",
    body: "Hello, I have a support request for Motionly regarding my account with e-mail '{{email}}'.",
  },
};

const customers = {
  sidebar: {
    settings: "Settings",
    members: "Members",
    license: "License",
  },
  properties: {
    name: "Name",
    role: "Role",
    status: "Status",
  },
  modal: {
    creationWarning:
      "Caution: the organization ID is permanent: you won't be able to edit it later!\nThe process will take about one minute, please be patient…",
    creationConfirm: "OK, create!",
  },
  settings: {
    uploadImageModal: {
      title: "Logo Change",
      explanation: "The logo will be used to represent this organization and when creating an creative share.",
      file: "Logo file",
    },
    title: "Settings",
    uploadLogo: "Change Logo",
    websiteUrl: "Website URL",
    websiteUrlPlaceholder: "Type a URL",
    websiteUrlDescription: "The website URL will be used when rendering creative share pages",
    privateId: "Private ID",
    country: "Country",
    industry: "Industry",
    companySize: "Company size",
  },
  members: {
    title: "Members",
    count: "{{count}} members",
    inviteMember: "Invite member",
    changeUserRole: "Change role",
    removeUser: "Remove from organization",
    transferExperiences: "Transfer creatives",
    loadingError: "The customers could not be loaded.",
    status: {
      onboarded: "Onboarded",
      disabled: "Disabled",
    },
    inviteMemberModal: {
      title: "Member Invitation",
      explanation: "You may invite people via their e-mail in order to collaborate.",
      role: "Role",
      sendInvitation: "Send",
      sendInvitationErrorTitle: "Invitation failed",
      success: "Invitation sent.",
    },
    changeRoleModal: {
      title: "Member Role",
      explanation: "You may change the role affected to a member to augment or decrease her permissions.",
      changeRole: "Change role",
      role: "Role",
      success: "Member role changed.",
      confirm: "Do you really want to change this member's role?",
      confirmTransferOwnership: "You are going to lose ownership of this organization. Do you really want to continue?",
      confirmSelf: "You are changing your own role, you may lose some capabilities. Do you really want to continue?",
    },
    removeFromCustomerConfirmationModal: {
      title: "Member Removal",
      explanation: "Do you really want to remove this member from organization?",
      action: "Remove",
      success: "Member removed.",
    },
    userTransferOwnershipModal: {
      title: "Creatives Ownership Transfer",
      explanation:
        "Please, indicate the member to whom all the creatives belonging to user '{{user}}' should be transferred to.",
      userPlaceholder: "Choose a member",
      confirmation: "Do you confirm the transfer?",
      action: "Transfer",
      submitSuccess: "Creatives ownership transferred.",
      submitFailure: "Something went wrong while transferring the creatives ownership.",
    },
  },
};

const applications = {
  list: {
    title: "Applications",
    count: "{{count}} applications",
    create: "Create",
    loadingError: "The applications could not be loaded.",
  },
  properties: {
    kind: "Kind",
    name: "Name",
    publicId: "Public ID",
    id: "ID",
    updateDate: "Updated",
    creationDate: "Created",
  },
  sidebar: {
    general: "General",
    parameters: "Parameters",
    callbacks: "Callbacks",
  },
  generalTab: {
    sdkUrl: "SDK URL",
  },
  parametersTab: {
    title: "Parameters",
    logLevel: "Logs verbosity",
    sdkVersion: "SDK version",
    resizingSolutionVendor: "Resizing solution vendor",
    disableAutoLoad: "Disable the auto-load",
    metricsLevel: "Metrics level",
    metricsDelayInMilliseconds: "Metrics delay in milliseconds",
    resizeDelayInMilliseconds: "Resize delay in milliseconds",
    useAssetsUrl: "CDN-proxy the assets",
    success: "Parameters saved.",
    formValidationErrors: {},
  },
  callbacksTab: {
    title: "Callbacks",
    modalTooltip: "Retrieve callbacks",
    savedCallbacksSuccess: "Application callbacks saved.",
    deletedCallbacksSuccess: "Application callbacks deleted.",
    modal: {
      title: "Application Callbacks",
      explanation:
        'The JavaScript "callbacks" functions are invoked during the SDK application lifecycle. From here, you can download a JavaScript file containing the boilerplate for the available functions.',
    },
  },
  createModal: {
    title: "Application Creation",
    explanation: "An application gathers in one place parameters which will be used when inserting an creative.",
    success: "Application created.",
    errorTitle: "Something went wrong during the application creation.",
  },
  deleteModal: {
    title: "Application Deletion",
    explanation: "Are you sure that you want to delete this application?",
    success: "Application deleted.",
    errorTitle: "Something went wrong during the application deletion.",
  },
};

const users = {
  null: "Undefined",
  notPresentInCustomer: "Unknown",
  sidebar: {
    profile: "My profile",
  },
  email: {
    title: "My e-mail",
    changeEmailDisabled: "You are signed in with Google, your e-mail can not be changed.",
    changeEmailConfirmationModal: {
      title: "E-mail modification",
      description:
        "In order to change your e-mail you are going to be disconnected. You will need to login again with your new e-mail address. Do you want to continue?",
      action: "OK, change e-mail and disconnect!",
    },
  },
  password: {
    title: "My password",
    currentPassword: "Current password",
    currentPasswordPlaceholder: "Type your current password",
    newPassword: "New password",
    newPasswordPlaceholder: "",
    changePasswordDisabled: "You are signed in with Google, your password can not be changed.",
  },
  profile: {
    title: "My Profile",
    uploadAvatar: "Change avatar",
    privateId: "Member private ID",
    uploadImageModal: {
      title: "Avatar Change",
      explanation: "The avatar image will be used to indicate when you are the owner of an creative.",
      file: "Avatar image file",
    },
  },
};

const sites = {
  table: {
    name: "Name",
    advertiser: "Advertiser",
    adUnit: "Ad Unit",
    template: "Template",
    owner: "Owner",
    updated: "Updated",
    status: "Status",
    actions: "",
  },
  status: {
    published: "Reviewing",
  },
  thirdPartyTagBulkDrawer: {
    title: "Settings",
    applyToAll: "Apply to all",
    downloadError: "An error occurred while downloading the third-party tag, please try again later.",
  },
  list: {
    noFilteredDataTitle: "No ad units found",
    noFilteredDataSubtitle: "Try another search or create a new ad unit",
    loadingError: "The ad units could not be loaded.",
    emptyPageTitle: "You have reached the end of the list",
    goBack: "Go to previous page",
  },
  actionsMenu: {
    thirdParty: "Third-Party Tag",
    duplicate: "Duplicate",
    publish: "Publish",
    placeholder: "Bulk actions",
    apply: "Apply",
  },
  thumbnail: {
    experiences: "{{COUNT}} creative(s)",
  },
  externalTrackingModal: {
    title: "Tracking and redirection",
    applyToAll: "Apply to all",
    siteNameLabel: "Multi ad-units creative's name: ",
    multiCTA:
      "This folder contains creatives multiple CTAs. Please add the tracking and redirection for each individual creative experience in the folder. This is a security measure to ensure all redirections are mapped to expected CTAs.",
    noExperiences: "There is no ad-units is this multi ad-units creative.",
    urlInputLabel: "Click redirection",
    urlInputPlaceholder: "E.g https://...",
    success: "The creatives tracking URLs has been successfully updated.",
    uniqueLinkCheckbox: "Apply to all ad-units",
    checkLink: "Check link",
  },
  detail: {
    lastUpdate: "Last updated",
    units: "units",
    noResultsDataTitle: "There is no ad-unit",
    noResultsDataDescription: "You may import or create it from a template",
    actionsMenu: {
      thirdParty: "Third-Party Tag",
      html5bundle: "HTML5 Bundle",
    },
    header: {
      addCreative: "Add creative",
      fromTemplate: "From template",
      import: "Import",
    },
  },

  duplicateDrawer: {
    title: "Duplicate multi ad-units",
    alert:
      "You may duplicate an existing Multi ad-units creative, which enables to set some modifications on the newly created multi ad-units creative, without modifying the original one.\nCopying a multi ad-units creative will also duplicate all its creatives.",
    successMessage: "Multi ad-units and child creatives duplicated successfully.",
    errorTitle: "Something went wrong during the multi ad-units duplication.",
  },

  duplicateModal: {
    title: "Multi ad-units creative Duplication",
    explanation:
      "You may duplicate an existing Multi ad-units creative, which enables to set some modifications on the newly created multi ad-units creative, without modifying the original one. Copying a multi ad-units creative will also duplicate all its creatives.",
    success: "Multi ad-units creative duplicated.",
    errorTitle: "Something went wrong during the multi ad-units creative duplication.",
  },
  experienceDuplicateModal: {
    title: "Duplicate creative",
    explanation:
      "In order to duplicate the existing creative inside a multi ad-units creative, you have to select a new ad unit. The new creative will be created after the builder saving.",
    success: "Creative duplicated.",
    errorTitle: "Something went wrong during the creative duplication.",
    loadingDestinationError: "Destinations can't be loaded at the moment.",
    adUnit: "Ad unit",
    adUnitPlaceholder: "Select ad unit",
    destination: "Destination",
    destinationPlaceholder: "Select destination",
    oldExperience: "Creative to duplicate",
    creativesList: "Creatives list",
    bespokeDuplicationInformation: "This creative is custom and cannot be duplicated to another ad-unit.",
    adUnitAlreadyExistsMessage: "This destination already contains the same ad unit.",
    noAdUnitAvailable: "There is no ad-unit available in the selected destination.",
  },
  experienceDeleteModal: {
    title: "Creative Removal",
    explanation:
      'Removing a creative will detach it from the multi ad-units creative. If you choose "Deep removal" option, it will also physically delete the creative itself.',
    success: "Creative removed.",
    errorTitle: "Something went wrong during the creative removal.",
    deepRemoval: "Deep removal",
    experience: "Creative to remove",
  },
  editSiteModal: {
    title: "Multi ad-units creative information Edition",
    success: "The multi ad-units creative information has been successfully saved.",
    errorTitle: "Something went wrong while saving the multi ad-units creative information.",
  },
  publishModal: {
    title: "Multi ad-units creative Publication",
    warning: "Warning about the publication of the multi ad-units creative",
    warningQuestion:
      "This will also publish the creatives involved. Are you sure that you want to publish this multi ad-units creative?",
    publishSuccessMessage: "Multi ad-units creative published.",
  },
  bulkPublishModal: {
    title: "Publish multi ad-units",
    warning: "This also publishes the creatives involved.",
    emptySelection: "Your selection does not contain any unpublished multi ad-units.",
    publishSuccessMessage: "Multi ad-units published.",
  },
  embedModal: {
    title: "Multi ad-units creative Third-Party Tag",
  },
  deleteModal: {
    title: "Multi ad-units creative Deletion",
    explanation:
      "Are you sure you want to delete the following multi ad-units creative? Once a multi ad-units creative is deleted, it cannot be recovered nor accessed anymore.",
    success: "Multi ad-units creative deleted.",
    errorTitle: "Something went wrong while deleting the multi ad-units creative.",
  },
  addExperienceDrawer: {
    title: "Add creative",
    warning: "Please follow Ogury naming convention",
    template: "Template",
    import: "Import",
    name: "Name",
    placeholder: "Select",
    file: "File",
    uploadText: "Drag and drop file here or browse",
    adUnit: "Ad unit",
    success: "The creative has been successfully created.",
    errorTitle: "Something went wrong during the creative unit creation.",
  },
  uploadExperienceBundleModal: {
    error: {
      experienceIsAlreadyAttached: "The experience with ID {{experienceId}} is already attached to this site.",
    },
  },
};

const experiences = {
  origins: {
    managed: "Managed",
    custom: "Custom",
    fromTemplate: "From Template",
    dynamic: "Dynamic",
  },
  status: {
    published: "Reviewing",
  },

  properties: {
    name: "Name",
    status: "Status",
    ratio: "Ratio",
    template: "Template",
    adUnit: "Ad unit",
    updateDate: "Updated",
    actions: "Actions",
  },

  preview: {
    preview: "Preview",
    detachedMention: "In detached mode",
    clickActionTitle: "The click action has been executed, here is the URL that corresponds to the clicked through: ",
    clickActionTitleNoUrl: "The click action has been executed, no tracking and redirection URL available.",
  },

  list: {
    noFilteredDataTitle: "No creative found",
    noFilteredDataSubtitle: "Try another search or create a new creative",
    loadingError: "The creatives could not be loaded.",
    emptyPageTitle: "You have reached the end of the list",
    goBack: "Go to previous page",
    selected: "selected",
  },

  detail: {
    loadingError: "The creative could not be loaded.",
    backToList: "Creatives",
  },

  sidebar: {
    general: "General",
    parameters: "Parameters",
    settings: "Settings",
    callbacks: "Callbacks",
    assets: "Assets",
    analytics: "Analytics",
  },

  generalTab: {
    privacy: "Privacy",
    template: "Template",
    templateVersion: "Template revision",
    features: "Features",
    model: "Model Version",
    manageTags: "Manage labels",
    private: "Private",
    public: "Public",
    updateNameSuccess: "Name saved.",
    updateCommentSuccess: "Comment saved.",
  },

  externalSyncFailedModal: {
    title: "Syncronization error",
    description: "An error occurred during synchronization with the Creative Console. Please try again.",
    synchronize: "Synchronize",
    error: "An error occurred during the synchronization process. Please try again.",
    success: "The creative has been successfully synchronized with the creative console.",
  },

  parametersTab: {
    name: "Parameters",
    shortTooltip: "Specifies the settings about the creative publishing",
    longTooltip: "…",
    dimensionsBuckets: "Dimensions",
    dimensionsBucketsTooltip: "Indicates the supported widths for the creative frame",
    quality: "Quality",
    qualityTooltip: "Specifies the quality of the creative rendering",
    dimensionsDensityPercentage: "Density Percentage",
    dimensionsDensityPercentageTooltip: "…",
    dimensionsUseDeviceDensity: "Device Density",
    dimensionsUseDeviceDensityTooltip: "…",
    renderingQuality: "Rendering Quality",
    renderingQualityTooltip: "…",
    ux: "UX",
    uxTooltip: "…",
    transition: "Transition",
    transitionTooltip: "…",
    metrics: "Metrics",
    metricsTooltip: "…",
    metricsType: "Type",
    metricsTypeTooltip: "…",
    metricsDelayInMilliseconds: "Delay (in ms)",
    metricsDelayInMillisecondsTooltip: "…",
    metricsLevel: "Level",
    metricsLevelTooltip: "…",
    setup: "Setup",
    setupTooltip:
      "Specifies when and how the creative should load and install. Do not touch those settings unless you know what you are doing!",
    setupRunMode: "Run Mode",
    setupRunModeTooltip: "…",
    setupWrap: "Wrap",
    setupWrapTooltip: "…",
    setupMode: "Mode",
    setupModeTooltip: "…",
    setupInstallVisibilityPercentageThreshold: "Install Percentage",
    setupInstallVisibilityPercentageThresholdTooltip: "…",
    setupLoadVisibilityPercentageThreshold: "Load Percentage",
    setupLoadVisibilityPercentageThresholdTooltip: "…",
    submitSuccess: "Parameters saved.",
  },

  callbacksTab: {
    name: "Callbacks",
    shortTooltip: "Specifies the JavaScript callbacks involved during the creative lifecycle",
    longTooltip: "…",
    modalTooltip: "Retrieve callbacks",
    enable: "Enable",
    enableTooltip: "When switched on, you may provide callbacks function to the creative.",
    viaFunction: "Use Function",
    viaFunctionTooltip:
      "When switched on, a JavaScript function name is expected (which must exist within the hosting web page) ; otherwise a JavaScript file is expected.",
    functionName: "JavaScript function name",
    submitSuccess: "Creative callbacks saved.",
    formValidationErrors: {
      functionName: "Invalid JavaScript function name",
      callbacks: "Invalid JavaScript file. Should not be empty",
    },
    modal: {
      title: "Creative Callbacks",
      explanation:
        'The JavaScript "callbacks" functions are invoked during the creative lifecycle. From here, you can download a JavaScript file containing the boilerplate for the available functions.',
    },
  },

  settingsTab: {
    name: "Settings",
    submitSuccess: "Settings saved.",
    inputsUrlTooltip:
      "This is the URL which will be requested when the creative loads: it is supposed to return a JSON content matching the template inputs.",
  },

  exportModal: {
    title: "Creative Media Export",
    description: "You may export a media from the creative, so as to share it on social networks or via e-mail.",
    shortTooltip: "Fulfill the required parameters so as to ask the server to create a media export of the creative.",
    longTooltip: "The background color is only necessary when the creative has some transparency.",
    mode: "Track or Score",
    types: {
      image: "Image",
      video: "Video",
      animatedGif: "Animated GIF",
    },
    track: "Track (ID)",
    score: "Score (ID)",
    concatenate: "Concatenate",
    percentage: "Percentage",
    width: "Width (in pixels)",
    format: "Format",
    quality: "Quality",
    backgroundColor: "Background Color",
    bitrateInKiloBytesPerSecond: "Bit Rate (in kB. per second)",
    framesPerSecond: "Frames Per Second (FPS)",
    formValidationErrors: {
      wrongWidth: "The width value must be even",
    },
    submitFailure: "Something went wrong while exporting the creative media.",
  },
  moveToModal: {
    title: "Move Creative",
    explanation:
      "Move the creative somewhere else. You can either select any multi ad-units creative that belong to you, or detach the creative from this multi ad-units creative.",
    experienceToMove: "Creative to move",
    destinationLabel: "Destination",
    successMessage: "Creative has been successfully moved.",
    adUnitAlreadyExistsMessage: "This destination already contains the same ad unit.",
    destinationSelector: {
      placeholder: "Select a destination",
      detachFromSite: "Detach from the multi ad-units creative",
      loadingError: "Destinations can't be loaded at the moment.",
    },
  },
  posterModal: {
    title: "Creative Poster",
    explanation:
      "The poster is an image which is displayed on the placeholder when the creative loads, and which is replaced by the animation once all assets have been loaded. This image has the same proportions as the creative frame ratio, i.e. the same value regarding the division of its width by its height. You may compute this poster from the creative animation at a given progress or provide your own file.",
    types: {
      none: "None",
      file: "File",
      compute: "Compute",
    },
    percentage: "Percentage",
    track: "Track",
    posterWidth: "Poster width (px)",
    quality: "Quality",
    backgroundColor: "Background color",
    none: "No poster will be displayed while the creative loads.",
    file: "Poster file",
    fileExplanation: "You will be prompted to crop the provided image if it does not match the creative frame ratio.",
    cropperExplanation: "Please, crop the image so that it marches the creative frame ratio.",
    createError: "An error occurred during the poster update.",
    createSuccess: "Poster updated.",
  },

  sharingModal: {
    title: "Creative Sharing",
    explanation:
      "You may create an creative preview web page, so as to share the result within your organisation and with your customers.",
    capsule: "Capsule (beta)",
    introduction: "Introduction",
    introductionTooltip: "The introduction text, displayed at the top of the page in upper cause.",
    shareTitle: "Title",
    shareTitleTooltip: 'The title text, displayed below the page "Introduction".',
    chapeau: "Chapeau",
    chapeauTooltip: 'The chapeau text, displayed below the page "Title".',
    footer: "Footer",
    footerTooltip:
      "The footer text, displayed at the bottom of the page, in upper case. You may use it to indicate credits, for instance.",
    brandLogo: "Brand logo URL",
    brandLogoPlaceholder: "Type a URL",
    brandLogoTooltip: "This logo will be displayed within the page top navigation bar.",
    context: "Context",
    contextTooltip: "What context the page should simulate to surround the creative.",
    contextValues: {
      article: "In article",
      merchandising: "In e-commerce",
      technical: "Technical (neutral)",
    },
    wrapping: "Wrapping",
    wrappingTooltip:
      'How much the creative should be surrounded in terms of fake contents above and below it: when an creative reacts to vertical scrolling (scroll-motion), use another value than "None".',
    wrappingValues: {
      none: "None (no wrapping)",
      short: "Short (about one screen-height)",
      long: "Long (about one and a half screen-height)",
    },
    bodyWidth: "Body width",
    bodyWidthTooltip:
      "The width, expressed in % of the page width, used to display the fake contents above and below the creative frame. It has no impact when the page is viewed on a mobile",
    experienceWidth: "Creative width",
    experienceWidthTooltip:
      "The width of the creative frame, expressed in % of the page width. It has no impact when the page is viewed on a mobile.",
    theme: "Dark mode",
    themeTooltip:
      'Check this if the "Background color" is dark and that the texts and logos should be displayed in white, instead of black.',
    backgroundColor: "Background color",
    backgroundColorTooltip: "The color of the page background.",
    sharingUrl: "Sharing URL",

    formValidationErrors: {
      bodyWidthPercentage: "Invalid value. Should be between {{MIN}} and {{MAX}}",
      experienceWidthPercentage: "Invalid value. Should be between {{MIN}} and {{MAX}}",
    },
    createSuccess: "Sharing created.",
    updateSuccess: "Sharing updated.",
    deleteSuccess: "Sharing deleted.",
    createError: "An error occurred during the creation of the sharing.",
    updateError: "An error occurred during the update of the sharing.",
    deleteError: "An error occurred during the deletion of the sharing.",
    deleteQuestion: "Do you really want to delete the sharing?",
    noSharing: "This creative has no available sharing link.",
  },

  tableSettingsModal: {
    title: "Table settings",
    description: "Quick access actions ({{maximumActions}} maximum).",
    success: "Settings successfully saved.",
  },

  actionsMenu: {
    thirdParty: "Download third party Tag",
    duplicate: "Duplicate",
    placeholder: "Bulk actions",
    apply: "Apply",
  },

  externalTrackingModal: {
    title: "Tracking and redirection",
    multiCTA:
      "This creative is based on a Multi-CTA template. Please manage the tracking and redirection directly within the builder.",
    creativeNameLabel: "Creative name: ",
    urlInputLabel: "Click redirection",
    urlInputPlaceholder: "E.g https://...",
    success: "The creative tracking URL has been successfully updated.",
  },

  inlineActions: {
    creativeConsoleLinkFailed: "CC Sync failed. Retry?",
  },

  capsuleModal: {
    title: "Creative Capsule (beta)",
    explanation:
      "Creating a capsule enables to get an illustration image that you may copy and paste so as to give access to creative preview page.",
    introduction: "Introduction",
    shareTitle: "Title",
    shareTitleTooltip: "The title will displayed at the top of the capsule",
    subTitle: "Subtitle",
    subTitleTooltip: "The subtitle will displayed below the title",
    isPortrait: "Portrait?",
    isPortraitTooltip: "Whether the capsule should be computed in portrait or landscape",
    poweredBy: "Powered By",
    poweredByTooltip: 'Whether the "Powered by" mention should be integrated',
    illustration: "Illustration",
    illustrationTooltip:
      'The illustration will be used for computing the capsule. Use the "Choose a file" option if you want to use your own file, or "Capture" if you want to take a snapshot of the creative rendering.',
    or: "or",
    capture: "Capture",
    cropperExplanation: "Please, crop the selected image into a square.",
    url: "Page URL",
    urlTooltip: "The URL of the page the capsule QR code and link should open",
    backgroundColor: "Background",
    backgroundColorTooltip: "This color will be used to fill the surface",
    ctaBackgroundColor: "CTA Color",
    ctaBackgroundColorTooltip: "This color will be used for the CTA background",
    ctaTextColor: "CTA Text",
    ctaTextColorTooltip: "This color will be used for the CTA text",
    void: 'Click on the "{{action}}" button to compute the capsule',
    highDefinition: "HD",
    copyWithLink: "Copy with link",
    copyImage: "Copy image",

    formValidationErrors: {
      text: "Invalid value. Should be less than {{MAX}} characters",
    },
    updateSuccess: "Capsule updated.",
    updateError: "An error occurred during the update of the capsule.",
  },

  upgradeModal: {
    title: "Upgrade creative",
    explanation: "Following creative is build with template revision {{currentRevision}}:",
    explanation2:
      "Template <0> revision {{latestRevision}}</0> is now available. Please check out the changes on the <1> template details page. </1>",
    version: "Version",
    upgradeSuccess: "Creative upgraded.",
    upgradeErrorTitle: "Something went wrong during the creative upgrade.",
  },

  privacyModal: {
    title: "Creative visibility",
    changePrivacySuccess: "Creative privacy changed.",
    private: "Private",
    public: "Public",
  },

  duplicateModal: {
    title: "Duplicate creative",
    explanation:
      "You may duplicate an existing creative, which enables to set some modifications on the newly created creative, without modifying the original one.",
    success: "Creative duplicated.",
    errorTitle: "Something went wrong during the creative duplication.",
  },
  assignAdUnitModal: {
    title: "Assign ad unit",
    success: "Ad unit successfully assigned.",
    errorTitle: "Something went wrong during the assignation of the ad unit.",
  },
  tagsModal: {
    title: "Creative Labels",
    customerTags: "Labels",
    searchOrCreate: "Search or create labels",
    searchPlaceholder: "Type a label",
    recent: "Recent labels",
    results: "{{number}} results",
    result: "{{number}} result",
    create: "Create new label",
    noTag: "There is no customer label.",
    noResult: "No label found.",
    showAllTags: "All existing label ({{number}})",
    hideTags: "Show less",
    createConfirmQuestion: "This label is about to be created:",
    formValidationErrors: {
      submitErrorTitle: "Something went wrong while applying the modifications.",
      searchError: "Error during labels search",
      createError: "Error during label creation",
    },
    applySuccess: "Labels updated.",
  },

  deleteModal: {
    title: "Creative Deletion",
    explanation:
      "Are you sure you want to delete the following creative? Once an creative is deleted, it cannot be recovered nor accessed anymore.",
    deleteAssociatedData: "Delete associated data",
    deleteAssets: "Delete assets",
    deleteMetrics: "Delete metrics",
    success: "Creative deleted.",
    errorTitle: "Something went wrong while deleting the creative.",
  },

  transferOwnershipModal: {
    title: "Creative Ownership Transfer",
    explanation: "You may change the creative ownership, which will enable the new owner to edit it.",
    message: "Are you sure you want to delete the following creative?",
    submitSuccess: "Creative ownership transferred.",
    submitFailure: "Something went wrong while transferring the creative ownership.",
  },

  download: {
    options: {
      description: "Download Description",
      inputs: "Download Inputs",
      bundle: "Download Bundle",
      html: "Download Preview",
    },
    modal: {
      title: {
        description: "Creative Download Description",
        inputs: "Creative Inputs Download",
        bundle: "Creative Bundle Download",
        html: "Creative Preview Download",
        download: "Download",
      },
      pleaseWait: "Please wait during the download…",
    },
  },

  publishModal: {
    title: "Creative Publication",
    warning:
      'Once a creative is published, you will have the ability to make changes. The "Published" status signifies that the creative is prepared for delivery.',
    warningQuestion: "Are you sure that you want to publish this creative?",
    publishSuccessMessage: "Creative published.",
  },

  publishOverModal: {
    title: "Publish over",
    warning:
      "Publishing over an existing creative will override the existing creative by the new one, and delete the hereby creative (its identifier, name, comment, labels will remain, though). Only published creatives can be replaced.",
    errorNotPublished: "This creative is not published and can not be overrided.",
    warningQuestion: "Are you sure that you want to replace this creative? This operation cannot be undone.",
    newExperience: "Will be replaced by:",
    oldExperience: "Creative to replace:",
    searchPlaceholder: "Creative name or ID",
    successMessage: "Creative replaced. You have been redirected to the published creative.",
    loadingDestinationError: "Destinations can't be loaded at the moment.",
  },
  experienceSelector: {
    placeholder: "Select a published creative",
    loadingError: "An error occurred while loading creatives, please try again later.",
  },

  archiveModal: {
    title: "Creative Archival",
    warning: "Once an creative is archived, its metrics stop being recording, and it will not be accessible anymore.",
    warningQuestion: "Are you sure that you want to archive this creative?",
    archiveSuccessMessage: "Creative archived.",
  },

  embed: {
    web: "Web",
    ecommerce: "E-Commerce",
    thirdParty: "Third-Party Tag",
    vpaidUrl: "VPAID URL",
    html5bundle: "HTML5 Bundle",
    minifiedTag: "Minified",
    solutionVendor: "Environment",
    solutionVendorTooltip: "Indicates into what environment the creative will run",
    solutionVendors: {
      default: "Default",
      ogury: "Ogury",
      googledv360: "Google DV 360",
      xandr: "Xandr",
      thetradedesk: "The Trade Desk",
      adform: "AdForm",
      adelphic: "Adelphic",
      amobee: "Amobee",
      avocet: "Avocet",
      beeswax: "Beeswax",
      dataxu: "Dataxu",
      mediamath: "MediaMath",
      oathbrightroll: "Oath - BrightRoll",
      smadex: "Smadex",
      stackadapt: "Stackadapt",
      taptapsonata: "Taptap / Sonata",
    },
    oguryEvents: {
      placeholder: "Type a URL",
      request: "Request",
      requestTooltip: "The URL that will be invoked when the creative has been requested",
      load: "Load",
      loadTooltip: "The URL that will be invoked when the creative has loaded",
      display: "Display",
      displayTooltip: "The URL that will be invoked when…",
      reach: "Reach",
      reachTooltip: "The URL that will be invoked when…",
      fail: "Fail",
      failTooltip: "The URL that will be invoked when the creative loaded or execution has failed",
    },
    modal: {
      title: "Creative Integration",
      thirdPartyTagTitle: "Creative Third-Party Tag",
      pleaseWait: "Please wait while the content is loading…",
      vpaid: {
        title: "Creative VPAID URL",
        explanation:
          "The VPAID URL enables to declare a creative within advertising environments, such as a Demand-Side Platform (DSP) or a Supply-Side Platform (SSP).",
      },
      thirdParty: {
        copyAnyway: "Copy anyway",
        experienceCopyWarning: 'The creative is still in "Draft" state, are you sure to copy this tag?',
        siteCopyWarning: 'The multi ad-units creative is still in "Draft" state, are you sure to copy this tag?',
        downloadAnyway: "Download anyway",
        experienceDownloadWarning: 'The creative is still in "Draft" state, are you sure to download this tag?',
        siteDownloadWarning:
          'The multi ad-units creative is still in "Draft" state, are you sure to download this tag?',
        explanation:
          "The third-party tag enables to declare a creative within advertising environments, such as a Demand-Side Platform (DSP) or a Supply-Side Platform (SSP).",
        experienceWarning: 'The creative is still in "Draft" state, hence think of publishing it before using it!',
        siteWarning:
          'The multi ad-units creative is still in "Draft" state, hence think of publishing it before using it!',
        application: "Application",
        applicationTooltip: "Indicates with what application this creative is supposed to run",
        width: "Width",
        widthPlaceholder: "Type the width of the creative frame",
        widthTooltip: "This width must match the one declared the DSP / SSP",
        impressionTag: "Impression Tag",
        impressionTagPlaceholder: "Paste or type the HTML impression tag",
        impressionTagTooltip:
          "This tag, which should be an HTML snippet, will be executed at a very early stage, even before the creative starts loading",
        withActionUrlPrefix: "With Action URL Prefix?",
        withActionUrlPrefixTooltip: "Indicates whether the tag should use a prefix for the action URL",
        actionUrlPrefix: "Action URL Prefix",
        actionUrlPrefixPlaceholder: "Type the DSP / SSP action URL macro",
        actionUrlPrefixTooltip:
          "This value will be used as a prefix to compute the URL of the content that will be opened when the end-user has performed an action with the creative leading to an action URL",
        oguryClickCommand: "Click Command",
        oguryClickCommandPlaceholder: "Paste the URL",
        oguryClickCommandTooltip:
          "This value will be used as the URL of the content that will be opened when the end-user has performed an action with the creative leading to an action URL",
        missingCustomerId:
          "Error: The tag could not be generated due to a credentials error. Please reload the app and try again.",
        errorLoadingDspOrApplication:
          "The application and DSP's list can't be loaded. Please reload the app and try again.",
        errorLoadingThirdPartyTag: "The third party tag can't be loaded. Please reload the app and try again.",
        downloadInfo:
          "Ogury recommends to download the code.\nCopy-paste the code into your favorite text editor may impact creatives due to additional invisible or invalid characters.",
      },
      html5Bundle: {
        explanation:
          "The creative HTML5 bundle is an archive zip file that may be used to run the creative in an advertising environment or any environment when it is possible to expose the creative assets.",
        get: "Get",
        type: "Vendor Environment",
        typeTooltip: "Indicates into what environment the creative will run",
        optimize: "Optimize",
        optimizeTooltip: "Runs an optimization over the PNG images by reducing their number of colors to 256",
        custom: "Custom",
        customTooltip: "Enables to define custom widths",
        width: "Width",
        widthPlaceholder: "Type the width for which the creative should be exported",
        widthTooltip:
          'Indicates the width / bucket for which the creative assets should be exported. When the "Include high-definition assets" checkbox is enabled, this width will be doubled.',
        includeHighDefinition: "Include high-definition assets",
        includeHighDefinitionTooltip:
          "Indicates whether the assets should include those for high definition (HD) screens. When checked, the bundle will contain also contain assets with a resolution doubled",
        buckets: "Widths",
        bucketsTooltip: "Indicates all the widths / buckets for which the creative assets should be exported",
        formValidationErrors: {
          width: "Invalid value. Should be between {{MIN}} and {{MAX}}",
          impressionTag: "Wrong HTML tag",
        },
        widthConstraintsIssue: "Cannot include the high-definition assets and reach a width greater than {{MAX}}",
      },
    },
  },
  analytics: {
    tableTitle: "Table",
    date: "Date (GMT)",
  },
};

const templates = {
  list: {
    title: "Templates",
    noFilteredDataTitle: "No template found",
    noFilteredDataSubtitle: "Try another search.",
    loadingError: "The templates could not be loaded.",
  },
  filters: {
    textSearch: "Name",
    ratio: "Ratio",
    industry: "Industry",
    errorTitle: "The filters data could not be loaded.",
  },
  details: {
    template: "Template",
    summary: "Summary",
    completeInfo: "Complete info",
    complexity: "Complexity",
    creator: "Creator",
    noDemos: {
      title: "Demo not found",
      subtitle: "There is no demo available for this template",
    },
    description: "Description",
  },
  preview: {
    template: "Click on a template to launch the preview",
    demos: "Click on a demo to launch the preview",
  },
  card: {
    demos: "Demos",
    static: "With Builder",
    revision: "From revision",
    dynamic: "Dynamic",
  },
  demoCard: {
    duplicate: "Duplicate",
  },
  sidebar: {
    general: "General",
    description: "Description",
    creations: "Creations",
  },
  createDynamicModal: {
    title: "Dynamic Experience Creation",
    explanation: "A dynamic creative is based on a template and on a source of inputs.",
    formValidationErrors: {
      ratioError: "The ratio must be positive",
    },
    creationSuccess: "Creative created.",
  },
  createFromRevisionModal: {
    title: "Create From Revision",
    explanation: "Please type a revision number version you want to use for the template '{{templateName}}'.",
    revision: "Template revision",
  },
};

const actions = {
  cancel: "Cancel",
  ok: "OK",
  save: "Save",
  saveChanges: "Save changes",
  send: "Send",
  search: "Search",
  create: "Create",
  add: "Add",
  update: "Update",
  upload: "Upload",
  filter: "Filter",
  copy: "Copy",
  get: "Get",
  openNewTab: "Open in a new tab",
  delete: "Delete",
  edit: "Edit",
  manageTags: "Labels",
  share: "Share",
  export: "Export",
  duplicate: "Duplicate",
  transferOwnership: "Transfer",
  upgrade: "Upgrade",
  experienceId: "Creative ID",
  experienceOrSiteId: "Creative ID",
  apply: "Apply",
  password: {
    show: "Show password",
    hide: "Hide password",
  },
  more: "More",
  clear: "Clear",
  close: "Close",
  download: "Download",
  integrate: "Integrate",
  preview: "Preview",
  externalTracking: "Tracking and redirection",
  publish: "Publish",
  publishOver: "Publish over",
  publishForReview: "Publish for review",
  moveTo: "Move to",
  archive: "Archive",
  confirm: "Confirm",
  reload: "Reload",
  design: "Design",
  poster: "Edit Poster",
  back: "Back",
  rename: "Rename",
  assignAdUnit: "Assign ad unit",
  demoPageLink: "Go to Demo page",
  creativeConsoleLink: "Go to Creative console",
};

const viewManagement = {
  defaultView: "Default View",
  deleteModalTitle: "View Deletion",
  deleteModalQuestion: "Are you sure you want to delete the view '{{name}}'?",
  createModalTitle: "View Creation",
  createModalExplanation: "You may create a view, which is a collection of creatives matching some criteria.",
  renameModalTitle: "View Renaming",
  renameModalExplanation: "You may rename the view.",
  saveAsNew: "Save as new view",
  saveSuccess: "View saved.",
  createSuccess: "View created.",
  renameSuccess: "View renamed.",
  deleteSuccess: "View deleted.",
};

const form = {
  optional: "Optional",
  showAll: "Show all",
  noData: "No data available",
  required: "This field is required",
  notEmpty: "This field should not be empty",
  invalidRange: "Invalid value. Should be between {{MIN}} and {{MAX}}",
  emailWrongFormat: "This e-mail address is not valid",
  errorMinLength: "The value is too short, it should contain at least {{LENGTH}} characters",
  errorMaxLength: "The value is too long, it should not exceed {{LENGTH}} characters",
  errorURL: "The URL is invalid. Make sure URL is starting with https://...",
  publicIdWrongFormat:
    "This ID is not valid: it should start with a letter, should contain less than 16 characters, should contain no spaces, no uppercase nor special characters",
  tagsWrongFormat: "Label is not valid, it should contains no spaces and no special characters",
  noDecimals: "This field should be an integer",
};

const fields = {
  email: "E-mail",
  emailPlaceholder: "Type an e-mail address, e.g. name@email.com",
  password: "Password",
  textPlaceholder: "Type a text",
  integerPlaceholder: "Type a number",
  name: "Name",
  account: "Account",
  accountPlaceholder: "Search an account",
  namePlaceholder: "Type a name",
  brand: "Brand",
  brandPlaceholder: "Select a brand",
  id: "ID",
  publicId: "Public ID",
  containerPolicy: "Container policy",
  containerPolicyPlaceholder: "Select a container policy",
  adUnit: "Ad unit",
  adUnitPlaceholder: "Select an ad unit",
  publicIdPlaceholder: "Type an ID",
  publicIdTooltip: "The public ID cannot be changed once the entity has been created.",
  comment: "Comment",
  commentPlaceholder: "Type a comment",
  imageAlternate: "Image",
  previewAlternate: "Preview",
  tags: "Labels",
  owner: "Owner",
  ratio: "Ratio",
  ratioPlaceholder: "Type a ratio",
  inputsUrl: "Inputs URL",
  urlPlaceholder: "Type a URL",
  customerName: "Organization name",
  customerNamePlaceholder: "Type a name, e.g. My company name",
  customerId: "Organization ID",
  customerIdPlaceholder: "Type an identifier, e.g. my-company-name",
  updateDate: "Updated",
  creationDate: "Created",
  javascriptSource: "JavaScript source-code",
  process: "Process",
  processTooltip:
    "When checked, the JavaScript code you provide will be processed so that it complies to ECMAScript v5.1 and will be minified",
  advanced: "Advanced",
  selectedFilename: "Selected file name:",
  preview: "Preview:",
  formValidationErrors: {
    name: "The name cannot exceed {{length}} characters",
    comment: "The comment cannot exceed {{length}} characters",
  },
  site: "Multi ad-units creative",
};

const filters = {
  advancedSearch: "Advanced Search",
  quickSearchPlaceholder: "Type some text",
  textSearch: "Name",
  owner: "Owner",
  ratio: "Ratio",
  adUnit: "Ad Unit",
  status: "Status",
  template: "Template",
  brand: "Advertiser",
  usage: "Creatives",
  brandPlaceholder: "Advertiser: all",
  adUnitPlaceholder: "Ad Unit : all",
  origin: "Origin",
  ux: "UX",
  industry: "Industry",
  favourites: "Favourites",
  customerTags: "Labels",
  templatePlaceholder: "Template: all",
  usagePlaceholder: "Creatives: all",
  tagsPlaceholder: "Labels: all",
  statusPlaceholder: "Status: all",
  ownerPlaceholder: "Owner: all",
  originPlaceholder: "Origin: all",
};

const auth = {
  googleAuth: " with Google",
  login: {
    title: "Log in",
    passwordForgotten: "Forgotten password?",
    dontHaveAnAccount: "Don't have an account?",
    authenticationError: "Authentication failure",
  },
  signup: {
    title: "Sign up",
    alreadyHaveAnAccount: "Already have an account?",
    action: "Create",
    createYourAccount: "Create your account",
    success: "Welcome to Motionly!",
    form: {
      username: "Your name",
      customerName: "Organization Name",
      customerId: "Organization ID",
    },
    confirmationModal: {
      cgu1: "Read our ",
      cgu2: "Privacy policy",
      cgu3: " and ",
      cgu4: "Terms of service",
      cgu5: "Accept our terms",
      title: "Your account is about to be created",
      description: "Confirm your info and finalize your account creation",
    },
  },
  join: {
    title: "You've been invited",
    action: "Join",
    success: "Welcome to Motionly!",
  },
  passwordForgotten: {
    back: "Back to login",
    send: "Reset password",
    success: "An e-mail with a new temporary password has been sent: please check your inbox.",
  },
};

const analytics = {
  filters: {
    interval: "Interval",
    currentWeek: "Current week",
    sevenDays: "7 days",
    lastWeek: "Last week",
    currentMonth: "Current month",
    thirtyDays: "30 days",
    lastMonth: "Last month",
    sixtyDays: "60 days",
    ninetyDays: "90 days",
  },
  noFilteredDataTitle: "No analytics found",
  noFilteredDataSubtitle: "Try another search criteria and make that at least one creative is published",
  noEventTitle: "No event found",
  noEventTitleSubtitle: "Try later on once some creatives have been published",
  latestEvents: "Latest events",
  events: {
    fail: "Fail",
    request: "Request",
    load: "Load",
    display: "Display",
    view: "View",
    show: "Show",
    open: "Open",
    scroll: "Scroll",
    reach: "Reach",
    complete: "Complete",
    import: "Import",
  },
  total: "Total",
  home: {
    title: "Analytics",
    tableTitle: "Per creative",
    loadingError: "The analytics could not be loaded",
  },
};

const messages = {
  errorTitle: "An error occurred",
  saveSuccess: "Data saved.",
  refreshSuccess: "Updated data.",
  defaultConfirmQuestion: "Are you sure you want to perform this action?",
  confirmCloseModal: "Are you sure you want to close this modal? Unsaved work will be lost.",
  errorOpeningBuilder: "An error occured while trying to open the Builder",
};

export default {
  app,
  components,
  navbar,
  experiences,
  sites,
  templates,
  analytics,
  actions,
  messages,
  form,
  fields,
  filters,
  auth,
  customers,
  applications,
  users,
  viewManagement,
};
