import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { HashRouter, Route, Switch, useLocation } from "react-router-dom";
import "Legacy/i18n/i18n";

import { AppLoader, Navbar } from "Legacy/components";
import { customerService, motionlyService } from "Legacy/services";
import { path, useNotificationService } from "Legacy/utils";
import { initializeApiClient } from "Legacy/services/MotionlyApiService";
import {
  getObject,
  LOCALSTORAGE_CREDENTIALS,
  LOCALSTORAGE_CURRENT_USER,
  LOCALSTORAGE_SETTINGS,
  LOCALSTORAGE_VERSION,
  persistObject,
} from "Legacy/utils/storage";

import "./App.scss";
import AppProvider from "Legacy/context/AppProvider";
import { useAuth } from "Legacy/utils/hooks";
import routes from "Legacy/router-config";
import { version } from "../package.json";
import StudioCompanion from "./app/studio/StudioCompanion";

function PageContent() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const auth = useAuth();

  async function initialize() {
    if (auth.isAuthenticated() === true) {
      await motionlyService.initializeBusinessReferences();
    }
    setInitialized(true);
  }

  useEffect(() => {
    initialize();
  }, []);

  function renderRoutableContent() {
    if (initialized) {
      return (
        <Switch>
          {routes.map(route => {
            const Component = route.component;
            return <Route key={route.key} path={route.path} render={props => <Component {...props} />} />;
          })}
        </Switch>
      );
    }
    return <></>;
  }

  function renderPageContent() {
    if (auth.isAuthenticated() === true && location.pathname.startsWith(path.JOIN) === false) {
      return (
        <div className="viewport-with-navbar">
          <Navbar currentRoute={location.pathname} />
          <section style={{ zIndex: 1000 }}>{renderRoutableContent()}</section>
        </div>
      );
    }
    return renderRoutableContent();
  }

  return renderPageContent();
}

const onVersion = async (newVersion, currentVersion) => {
  // console.debug(`Running from version '${currentVersion}' to version '${newVersion}'`);
  try {
    if (currentVersion === undefined && newVersion === "") {
      // eslint-disable-next-line no-console
      console.debug(`Migrating into version '${newVersion}'`);
      // const object = {};
      // // eslint-disable-next-line no-plusplus
      // for (let index = 0; index < localStorage.length; index++) {
      //   const key = localStorage.key(index);
      //   object[key] = localStorage.getItem(key);
      // }
      // console.dir(object);
      customerService.changeCustomer(undefined, "/");
      return false;
    }
  } finally {
    persistObject(LOCALSTORAGE_VERSION, newVersion);
  }
  return true;
};

function App() {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const persistedCredentials = getObject(LOCALSTORAGE_CREDENTIALS);
  const persistedUser = getObject(LOCALSTORAGE_CURRENT_USER);
  const persistedSettings = getObject(LOCALSTORAGE_SETTINGS);

  let customerFound = false;
  let rootCustomerId;

  if (persistedCredentials !== undefined && persistedUser?.id !== undefined) {
    // Matches the (".../c/230492834029438/...") pattern
    const regexp = new RegExp(`(\\/${path.ROOT_CUSTOMER_PREFIX}\\/[\\d]+\\/)+`, "g");
    const found = window.location.href.match(regexp);
    // The next 4 lines check whether the URL is well formatted
    if (found !== null && found[0] !== undefined) {
      const split = found[0].split("/");
      if (split.length >= 3) {
        const customerId = split[2];
        // console.debug(`Recognized in the URL the customer with id '${customerId}'`);
        // eslint-disable-next-line array-callback-return
        persistedUser.roles.list.map(role => {
          if (customerFound === true) {
            // eslint-disable-next-line array-callback-return
            return;
          }
          // We check whether the current user has the customer in its customers, otherwise we revert to the last stored customer
          if (role.customerId.toString() === customerId.toString()) {
            customerFound = true;
            // We first update the variables that do not have side effects…
            rootCustomerId = `/${path.ROOT_CUSTOMER_PREFIX}/${customerId}/`;
            customerService.setCurrentCustomerId(customerId);
            // … and then only the structural change
            if (persistedSettings?.lastCustomerId?.toString() !== customerId.toString()) {
              // If this is not the last customer id, we force the account switch
              // console.debug(`Switching to the customer with id '${customerId}'`);
              customerService.changeCustomer(customerId, undefined);
            }
          }
        });
      }
    }

    if (persistedUser !== undefined && customerFound === false) {
      // If this happens, it means that either the URL does not contain the customer id, or that the "lastCustomerId" does not correspond to any of to the newly authenticated user's customers: we select the first one
      // console.debug("Could not guess the customer id from the URL");
      if (persistedUser.roles.list.length >= 1) {
        let lastCustomerId = persistedSettings?.lastCustomerId;
        if (
          lastCustomerId !== undefined &&
          persistedUser.roles.list.find(role => role.customerId?.toString() === lastCustomerId?.toString()) === false
        ) {
          lastCustomerId = undefined;
        }
        const customerId = lastCustomerId !== undefined ? lastCustomerId : persistedUser.roles.list[0].customerId;
        // console.debug(`Switching to the customer with id '${customerId}'`);
        rootCustomerId = `/${path.ROOT_CUSTOMER_PREFIX}/${customerId}/`;
        customerFound = true;
        customerService.setCurrentCustomerId(customerId);
        customerService.changeCustomer(customerId, rootCustomerId);
      } else {
        notificationService.notifyError(t("app.loadingError"), t("app.oops"));
      }
    }

    if (customerFound === true) {
      customerService.ensureCurrentCustomer();
    }
  }

  useEffect(() => {
    const run = async () => {
      try {
        if ((await onVersion(version, getObject(LOCALSTORAGE_VERSION))) === true) {
          const { simulatedEnvironment, isLegacy } = await initializeApiClient();
          await motionlyService.initialize(simulatedEnvironment, isLegacy);

          StudioCompanion.initialize(motionlyService);
          setInitialized(true);
        }
      } catch (error) {
        notificationService.notifyError(t("app.loadingError"), t("app.oops"));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [t]);

  return (
    <>
      {initialized === true ? (
        <AppProvider>
          <HashRouter hashType={path.HASH_TYPE} basename={rootCustomerId}>
            <PageContent />
          </HashRouter>
        </AppProvider>
      ) : (
        <AppLoader />
      )}
    </>
  );
}

export default App;
