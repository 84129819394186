import { Experience } from "@ogury/motionly-ws-api/ws";
import ExternalLink from "@ogury/motionly-ws-api/ws/com.koppasoft.motionly.client.model/ExternalLink";
import StudioCompanion from "../app/studio/StudioCompanion";
import { Environment, experienceService, userPermissions } from ".";

const { StateEnum, OriginEnum } = Experience;
const { Draft, Published, Archived } = StateEnum;
const { FromTemplate, Dynamic } = OriginEnum;

const VPAID_TEMPLATES_ID = ["videovpaid"];

// const currentUserIsExperienceOwner = experience => experience.ownerId === userService.getCurrentUserId();

/* eslint-disable no-unused-vars */
const currentUserHasWritePermission = experience => {
  return userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps();
};

// Experience state helpers
const experienceIsDraft = experience => experience.state === Draft;
const experienceIsPublished = experience => experienceService.experienceIsPublished(experience);
const experienceIsArchived = experience => experience.state === Archived;

// Experience origin helpers
const experienceIsFromTemplate = experience => experience.origin === FromTemplate;
const experienceIsDynamic = experience => experience.origin === Dynamic;

const canSeeExternalLink = (experience, externalLinkType) => {
  return experienceService.getExternalLinkByType(experience, externalLinkType) !== undefined;
};

export default {
  experienceIsPublished,
  canViewList: () => true,
  // Experience creation
  canCreate: () => userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps(),

  // Experience view management
  canManageViews: () => userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps(),

  // Experience sharing
  sharing: {
    canShare: experience => experienceIsArchived(experience) === false,
    canExport: experience => experienceIsArchived(experience) === false,
    canViewShareSettings: experience =>
      !experienceIsArchived(experience) && userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps(),
    canCreate: experience => !experienceIsArchived(experience) && currentUserHasWritePermission(experience),
    canUpdate: experience => !experienceIsArchived(experience) && currentUserHasWritePermission(experience),
    canDelete: experience => !experienceIsArchived(experience) && currentUserHasWritePermission(experience),
  },

  // Experience download
  download: {
    canDownloadDescription: experience => !experienceIsDynamic(experience),
    canDownloadInputs: experience => experienceIsFromTemplate(experience),
    canDownloadBundle: experience => !experienceIsDynamic(experience),
    canDownloadHtml: experience => experienceIsArchived(experience) === false,
  },

  // Experience Integration
  canEmbed: (experience, environment) =>
    experienceIsArchived(experience) === false &&
    (environment === undefined ? true : environment !== Environment.HTML5Bundle || experience.origin !== Dynamic),

  canUseVpaidURL: experience => {
    const template = experienceService.getTemplate(experience);
    return VPAID_TEMPLATES_ID.includes(template?.id);
  },

  // Experience metadata edition (name, comment, privacy)
  canEditName: experience => currentUserHasWritePermission(experience),
  canEditComment: experience => currentUserHasWritePermission(experience),
  canChangePrivacy: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),

  // External links
  canSeeCreativeConsoleLink: experience => {
    return canSeeExternalLink(experience, ExternalLink.TypeEnum.CREATIVE_CONSOLE);
  },
  canSeeDemoPageLink: experience => {
    return canSeeExternalLink(experience, ExternalLink.TypeEnum.DEMO_SHOWCASE);
  },

  // Experience edition (Form)
  canSeeEditButton: experience => experienceIsFromTemplate(experience) || StudioCompanion.withStudio() === true,
  shouldSeeEditButton: experience => experienceIsFromTemplate(experience),
  canEdit: experience =>
    experienceIsFromTemplate(experience) &&
    // experienceIsDraft(experience) && // no longer have required to disable save if experience is draft
    (currentUserHasWritePermission(experience) || userPermissions.currentUserIsOps()),
  canDelete: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  canTransferOwnership: experience => currentUserHasWritePermission(experience),
  canUpgrade: experience => currentUserHasWritePermission(experience),
  couldBePublished: experience => experienceIsDraft(experience),
  canPublish: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  canPublishOver: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  couldBeArchived: experience => experienceIsPublished(experience),
  canArchive: experience => experienceIsPublished(experience) && currentUserHasWritePermission(experience),
  canDuplicate: () => userPermissions.currentUserIsOwnerOrAdminOrCreatorOrOps(),
  canManageTags: experience => currentUserHasWritePermission(experience),
  canPoster: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  canMoveTo: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  canAssignAdUnit: experience => !experienceIsFromTemplate(experience) && currentUserHasWritePermission(experience),
  // Experience settings tab
  settings: {
    // eslint-disable-next-line arrow-body-style
    canView: experience => {
      return experience?.origin === Dynamic && currentUserHasWritePermission(experience);
    },
    canModify: experience => {
      const isOriginOk = experienceIsFromTemplate(experience) || experienceIsDynamic(experience);
      return experienceIsDraft(experience) && isOriginOk && currentUserHasWritePermission(experience);
    },
  },

  // Experience callbacks tab
  callbacks: {
    canView: experience => currentUserHasWritePermission(experience),
    canModify: experience => experienceIsDraft(experience) && currentUserHasWritePermission(experience),
  },

  // Experience parameters tab
  parameters: {
    canView: experience => currentUserHasWritePermission(experience),
    canModify: experience => {
      const isOriginOk = experienceIsFromTemplate(experience) || experienceIsDynamic(experience);
      return experienceIsDraft(experience) && isOriginOk && currentUserHasWritePermission(experience);
    },
  },

  // Experience assets tab
  assets: {
    canView: experience => currentUserHasWritePermission(experience) && false,
  },

  // Experience analytics tab
  analytics: {
    canView: experience => experience?.state === Published,
  },

  // Experience preview
  canPreview: () => true,
  canCreateStandaloneExperience: () => userPermissions.currentUserIsOwnerOrAdmin(),
};
